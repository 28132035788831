import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import useNotifications from "@/composables/useNotifications";
import { updateLocation, _getLocations } from '@/@core/queries/locations';
import i18n from '@/libs/i18n';
import useCommon from '@/views/organization/useCommon'

export default function useLocationList() {
  const { showErrorMessage } = useNotifications();
  const { handleError } = useCommon()
  const refLocationListTable = ref(null)
  const locations = ref([])
  const userData = store.state?.userStore?.userData
  const zoneAgencyFilter = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).zone_agency_filter
      : false

  // Table Handlers
  const tableColumns = computed(() => {
    let headers = [
      {
        key: 'location',
        label: i18n.t('message.tableHeader.location'),
        sortable: true,
      },
      {
        key: 'Actions',
        label: i18n.t('message.tableHeader.actions'),
      },
    ]
    if (zoneAgencyFilter) {
      headers.splice(1, 0,
        {
          key: 'zone',
          label: i18n.t('message.tableHeader.zone'),
          sortable: true,
        },
        {
          key: 'agency',
          label: i18n.t('message.tableHeader.agency'),
          sortable: true,
        }
      )
    }
    return headers
  })

  const perPage = ref(10)
  const totalLocations = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('location')
  const isSortDirDesc = ref(false)
  const statusFilter = ref(null)
  const isLoading = ref(true)

  const refetchData = () => {
    if (refLocationListTable.value) {
      refLocationListTable.value.refresh()
    }
  }

  const fetchLocations = () => {
    isLoading.value = true
    store
      .dispatch('organization-location/fetchLocations', {
        status: statusFilter.value,
        query: _getLocations,
        variables: {
          input: {
            limit: perPage.value,
            total: currentPage.value === 1,
            offset: (currentPage.value * perPage.value) - perPage.value,
            query: {},
            search: searchQuery.value,
            sortBy: sortBy.value,
            sortOrder: isSortDirDesc.value ? -1 : 1,
            client_id: ( userData.role != "admin" ) ? userData.client.$oid : null
          }
        }
      })
      .then(response => {
        if (response.data.errors) throw new Error(response.data.errors[0].message)
        const {
          LocationsData: {
            locations: w,
            totalRecords: total
          }
        } = response.data.data || {}
        locations.value = w
        if (currentPage.value === 1) totalLocations.value = total
      })
      .catch((error) => {
        console.log(error)
        handleError({ error, defaultMessage: i18n.t('message.location_list_fetch_error') })
      })
      .finally(() => {
        isLoading.value = false
      })
  }

  const deleteLocation = (userId, callback) => {
    store
      .dispatch('organization-location/deleteLocation', {
          query: updateLocation,
          variables: { query: { _id: userId }, data: {deleted: true} 
        },
      })
      .then(() => {
        callback(true)
      })
      .catch(() => {
        callback(false)
        showErrorMessage(i18n.t('message.something_went_wrong'))
      })
  }


  watch([currentPage, perPage, statusFilter], () => {
    fetchLocations()
  })

  watch([searchQuery, sortBy, isSortDirDesc], () => {
    currentPage.value = 1
    fetchLocations()
  })

  return {
    fetchLocations,
    tableColumns,
    perPage,
    currentPage,
    totalLocations,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLocationListTable,
    locations,
    statusFilter,
    refetchData,
    deleteLocation,
    isLoading,
  }
}